/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';
import { translations } from '../locales/translations';
import { PathConstant } from './constants/path.constant';
import { DashboardPage } from './pages/DashboardPage/Loadable';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage/Loadable';

import { HomePage } from './pages/HomePage/Loadable';
import { LoginPage } from './pages/LoginPage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { RootPage } from './pages/RootPage/Loadable';
import { SettingPage } from './pages/SettingPage/Loadable';
import { SettlementInfoPage } from './pages/SettlementInfoPage/Loadable';
import PrivateSignedInRoute from './routes/private-signed-in.route';
import PrivateSignedOutRoute from './routes/private-signed-out.route';

export function App() {
  const { t } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate={`%s - ${t(translations.LABEL.PLATING)}`}
        defaultTitle={t(translations.LABEL.PLATING)}
      >
        <meta name="description" content={t(translations.LABEL.PLATING)} />
      </Helmet>

      <Switch>
        <Route exact path={PathConstant.ROOT} component={RootPage} />
        <PrivateSignedOutRoute
          exact
          path={PathConstant.LOGIN}
          component={LoginPage}
        />
        <PrivateSignedOutRoute
          exact
          path={PathConstant.FORGOT_PASSWORD}
          component={ForgotPasswordPage}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.HOME}
          component={() => <HomePage title="" children={<DashboardPage />} />}
        />
        {/* <PrivateSignedInRoute
          exact
          path={PathConstant.SETTLEMENT_INFO}
          component={() => (
            <HomePage title="" children={<SettlementInfoPage />} />
          )}
        /> */}
        <PrivateSignedInRoute
          exact
          path={PathConstant.SETTING}
          component={() => <HomePage title="" children={<SettingPage />} />}
        />
        <Route component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
